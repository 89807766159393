.panel-holder {
  -webkit-perspective: 800px; /* Safari 4-8 */
  perspective: 800px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.art-header {
  position: absolute;
  top: 10%;
  right: 15%;
}

.full-art-holder {
  position: absolute;
  /*  width: 60%;
  height: 60%;
  left: 50%;
  margin-left: -30%;
  top: 19%; */

  width: 40%;
  height: 40%;
  left: 50%;
  margin-left: -20%;
  top: 29%;
  /* border: 3px dashed lime; */
  pointer-events: none;
}

.each-panel-holder {
  position: absolute;
  width: 15%;
  height: 15%;
}
.each-panel-holder.receded {
  /* z-index: -2; */
}

.each-panel-holder.panel1 {
  /* background-position: 15% 20px; */
  top: 22%;
  left: 25%;
  width: 10%;
  height: 10%;
}

.each-panel-holder.panel2 {
  /* background-position: 15% 20px; */
  top: 34%;
  left: 13%;
  width: 23%;
  height: 14%;
}

.each-panel-holder.panel3 {
  /* background-position: 15% 20px; */
  top: 30%;
  left: 37%;
  width: 13%;
  height: 18%;
}

.each-panel-holder.panel4 {
  top: 21%;
  left: 52%;
  width: 18%;
  height: 14%;
}

.each-panel-holder.panel5 {
  top: 15%;
  left: 72%;
  width: 13%;
  height: 20%;
}

.each-panel-holder.panel6 {
  top: 37%;
  left: 52%;
  width: 33%;
  height: 11%;
}

.each-panel-holder.panel7 {
  top: 52%;
  left: 18%;
  width: 24%;
  height: 14%;
}

.each-panel-holder.panel8 {
  top: 68%;
  left: 30%;
  width: 12%;
  height: 12%;
}

.each-panel-holder.panel9 {
  top: 52%;
  left: 44%;

  width: 8%;
  height: 12.94%;
}

.each-panel-holder.panel10 {
  top: 52%;
  left: 54%;

  width: 30%;
  height: 10%;
}

.each-panel-holder.panel11 {
  top: 64%;
  left: 54%;

  width: 12%;
  height: 12%;
}

.each-panel-holder.panel12 {
  top: 64%;
  left: 68%;

  width: 15%;
  height: 20%;
}

.panel {
  background-image: url("../images/domestic_interior_2.jpg");
  cursor: pointer;
  transform-style: preserve-3d;
  height: 100%;
  width: 100%;
  background-color: #456;
  position: absolute;
  top: 0px;
  transform-origin: top center;
  border: 3px inset #b8c6d0;
}

.panel.active {
  /* display: none; */
}

.panel-art {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #23ff53;
  z-index: -1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  border: 4px solid #666;
  box-shadow: inset 0 0 0 0px rgb(133, 133, 133);
}

.panel-art .art-img {
  width: 100%;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  cursor: pointer;
}
