html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff8e1;
}

.page-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
}

.loaded-page .page-holder,
.loaded-page .page-holder > div {
  width: 100%;
}

.image-loader-holder {
  display: none;
}

/* Progress Bar */
.progress {
  position: relative;
  height: 4px;
  display: block;
  width: 60%;
  background-color: #ffe0b2;
  border-radius: 2px;
  background-clip: padding-box;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;
}

.progress .indeterminate {
  background-color: #ef6c00;
}
.progress .indeterminate:before {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
    infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.progress .indeterminate:after {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.central-background {
  position: fixed;
  bottom: 0px;
  height: 80%;
  width: 100%;
  background-image: url("../images/desk_center.jpg");
  background-position: 0px 00px;
  background-size: 100% 5000%;
}

.page-background {
  position: fixed;
  visibility: hidden;
  opacity: 0;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 30%; /*50%*/
  height: 100%;
  width: 100%;
  /* opacity: 0; */
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;

  /* display: none; */
}

.sheepme {
  background-image: url("../images/sheepme.jpg");
  /* background-color: ##cbcdd7; */
}
.queenme {
  background-image: url("../images/queenme.jpg");
  /* background-color: #b4967c; */
}
.obamame {
  background-image: url("../images/obamame.jpg");
  /* background-color: #e9d6bd; */
}
.pikame {
  background-image: url("../images/pikame.jpg");
  /* background-color: #8b8784; */
}
.warholme {
  background-image: url("../images/warholme.jpg");
  /* background-color: #fed2f7; */
}
.pumpkinme {
  background-image: url("../images/pumpkinme.jpg");
  /* background-color: #e8f9fd; */
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.planeTop.receded .background,
.planeBottom.receded .background,
.planeLeft.receded,
.planeRight.receded {
  /* filter: blur(7px);
  filter: hue-rotate(90deg); */
  filter: brightness(0.75);
}

/* .page-holder,
.page-holder > div {
  width: 100%;
  height: 1000px;
} */
.planeTop {
  width: 100%;
  height: 20%;
}

.planeTop .background {
  content: "";
  background-image: url("../images/waterlandscape.jpg");
  background-color: #b1c1dd;
  height: 20%;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  filter: blur(2px);
  -webkit-clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
  clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
}
.planeBottom {
  position: absolute;
  top: 80%;
}
.planeBottom .background {
  background-image: url("../images/desk_bottom.jpg");
  background-position: 0px 00px;
  background-size: 100%;
  background-color: #a5852b;
  height: 20%;
  /* height: 80%; */
  background-size: 100% 200%;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;

  -webkit-clip-path: polygon(
    0% 100%,
    22% 0%,
    82% 0%,
    89% 27%,
    76% 42%,
    80% 43%,
    78% 52%,
    89% 53%,
    88% 58%,
    92% 59%,
    94% 69%,
    92% 72%,
    95% 74%,
    98% 88%,
    100% 100%
  );
  clip-path: polygon(
    0% 100%,
    22% 0%,
    82% 0%,
    89% 27%,
    76% 42%,
    80% 43%,
    78% 52%,
    89% 53%,
    88% 58%,
    92% 59%,
    94% 69%,
    92% 72%,
    95% 74%,
    98% 88%,
    100% 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.planeLeft {
  background-image: url("../images/domestic_interior_1.jpg");
  background-color: #f3f3d3;
  height: 100%;
  width: 20%;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  /* background: lime; /* Just to visualize the extent */
  -webkit-clip-path: polygon(0 0, 100% 20%, 100% 80%, 0 100%);
  clip-path: polygon(0 0, 100% 20%, 100% 80%, 0 100%);
}

.planeRight {
  background-color: #f1e9b9;
  background-image: url("../images/domestic_interior_2.jpg");
  height: 100%;
  width: 20%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  -webkit-clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 80%);
  clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 80%);
}

.loading .planeRight,
.loading .planeLeft,
.loading .planeTop,
.loading .planeBottom {
  background-image: none;
  border: 1px solid red;
}
.loading .planeBottom {
}

.dev {
  position: absolute;
  top: 30%;
  left: 30%;
  color: #aaa;
}

.sky-video-background {
  /* display: none;*/
  opacity: 0;
}

.video-holder {
  /* -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%); */
}
